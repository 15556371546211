import { Container, Divider, Grid, IconButton, Paper, Typography, useTheme } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import SectionTitle from "../../../components/sectionTitle";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import EntityFinder from "../../../components/entities/EntityFinder";

export default function SubscriptionAdd()
{
    const {t, i18n} = useTranslation();

    const [isOpenFromDialog,setIsOpenFromDialog] = useState(false);
    
    const theme = useTheme();
    const history = useHistory();

   return(
        <Container maxWidth='xl'>
            <CustomBreadcrumbs
                crumbs={[
                    {name:`${t('subscriptions.title')}`,link:'/subscriptions'},
                    {name:`${t('actions.add')}`,link:'/subscriptions/add'}
                ]}
            />
            <SectionTitle title={t('subscriptions.add')}/>
            <br/>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper elevation={4}>
                        <Grid container spacing={3} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                            {/**Columna del pagador */}
                            <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
                                <Grid container spacing={3}>
                                    <Grid item xs={10} sm={10} md={10} lg={11} xl={11}>
                                        <Typography variant="h6">Pagador</Typography>
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={2} lg={1} xl={1}>
                                        <IconButton onClick={()=>setIsOpenFromDialog(!isOpenFromDialog)}>
                                            <FontAwesomeIcon icon={faPencil}/>
                                        </IconButton>
                                    </Grid>
                                    <EntityFinder
                                        title="Buscar entidad (Pagador)"
                                        isOpen={isOpenFromDialog}
                                        onClose={()=>setIsOpenFromDialog(false)}
                                    />

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        DATOS DE LA PERSONA
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/**Columna del divisor */}
                            <Grid item lg={1} xl={1} display={'flex'} justifyContent={'center'}>
                                <Divider orientation="vertical"/>
                            </Grid>

                            {/**Columna del beneficiario */}
                            <Grid item xs={12} sm={12} md={55} lg={5} xl={5}>
                                <Grid container spacing={3}>
                                    <Grid item xs={10} sm={10} md={10} lg={11} xl={11}>
                                        <Typography variant="h6">Beneficiario</Typography>
                                    </Grid>
                                    <Grid item xs={2} sm={2} md={2} lg={1} xl={1} display={'flex'} flexDirection={'row'} justifyContent={'right'}>
                                        <IconButton>
                                            <FontAwesomeIcon icon={faPencil}/>
                                        </IconButton>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        DATOS DE LA PERSONA
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
   )
}
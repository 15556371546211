import WebsiteRoutes from "../apiRoutes/WebsiteRoutes";
import { GET, POST, POST_JSON } from "./ConsumeApiController";

export async function create_preference_id(title,amount)
{
    const response = await POST_JSON(
        WebsiteRoutes.Payments.MercadoPago.createPreferenceId,
        {title,amount}
    );
    return response;
}

export async function process_payment(payment_data,preference_id)
{
    const response = await POST_JSON(
        WebsiteRoutes.Payments.MercadoPago.processPayment,
        {payment_data,preference_id}
    );

    return response;
}
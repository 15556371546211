import { url } from "./apiBase";

const CompanyRoutes = {
    Ciudades:
    {
        GetAll: url + 'configuracion/ciudades/all',
    },
    Profile:
    {
        GetDatos : url + 'perfil/shop/datos',
        GetImagenes : url + 'perfil/shop/imagenes',
        UpdatePicture: url + 'perfil/shop/imagenes/update',
        UpdateDatosEmpresa: url + 'perfil/shop/datos/update',
        UpdateDatosContacto: url + 'perfil/shop/contactos/update',
        UpdateCoordenadas: url + 'perfil/shop/coordenadas/update',

        GetRedesSociales: url + 'perfil/shop/redesSociales/get',
        AddRedSocial: url + 'perfil/shop/redesSociales/add',
        UpdateRedSocial: url + 'perfil/shop/redesSociales/update',

        GetHorarios: url + 'perfil/shop/horarios/get',
        AddHorario: url + 'perfil/shop/horarios/add',
        UpdateHorario: url + 'perfil/shop/horarios/update',
    },
    Products:
    {
        GetProducts: url + 'productos/negocio/',//completar con el uuid del comercio
        ChangeStatus: url + 'productos/changestatus',
        GetForAdd: url + 'productos/forAdd',
        Add: url + 'productos/add',
        Update: url + 'productos/update',
    },
    SubCategories: 
    {
        GetAll: url + 'subcategorias/all',
        ChangeStatus: url + 'subcategorias/changestatus',
        Add: url + 'subcategorias/add',
        Update: url + 'subcategorias/update'
    },
    Coupons:
    {
        GetAllFromMerchant: url + 'coupons/allFromMerchant',
        Add: url + 'coupons/add',
    },
    Associates:
    {
        GetAll: url + 'associates/all',
        GetByUUID: url + 'public/associates/byuuid/', //completar con uuid
        Add: url + 'associates/add',
        Update: url + 'associates/update'
    },
    Events:
    {
        GetAll: url + 'eventos/status/', //completar con el status del evento
        ToStore: url + 'eventos/tostore',
        Add: url + 'eventos/create',
        Show: url + 'eventos/show/', //completar con el ID del evento
        Update: url + 'eventos/update/', // completar 
        ChangeStatus: url + 'eventos/destroy/', //completar con el ID del evento
        Categories:
        {
            All: url + 'eventos/categorias'
        },
        Sections:
        {
            Create: url + 'eventos/secciones/create',
            List: url + 'eventos/secciones/', //completar con el ID del evento
            ChangeStatus: url + 'eventos/secciones/destroy/', //completar con el UUID de la seccion
            Update: url + 'eventos/secciones/update/', //completar con el UUID de la seccion
        },
        Tickets:
        {
            GetImages: url + 'eventos/boleto/', //completar con ID del evento
            Create: url + 'eventos/boleto/create', //sin parametro para crear, para actualizar mandar el uuid del registro
            Issue: url + 'comercios/pedido/boleto',
            SoldByEvent: url + 'comercios/pedido/boletos/vendidos/', //completar con evento_id
            Destroy: url + 'eventos/tickets/destroy/', //completar con {uuid}/{status}
        },
        Sponsors:
        {
            GetAllByEvent: url + 'eventos/patrocinadores/', //completar com {evento_id}
            Create: url + 'eventos/patrocinadores/create',
        },
        Gastromedia:
        {
            GetCompetitor: url + 'eventos/gastromedia/', //completar con el id del evento
        },
        Products:
        {
            GetProducts: url + 'comercios/evento/', //completar con evento_id
            SetProductEvent: url + 'comercios/evento/store',
        }
    },
    Finances:
    {
        BankAccounts:
        {
            GetToCrerate: url + 'finanzas/nrocuentas/create',
            Add: url + 'finanzas/nrocuentas/create',
            GetAll: url + 'finanzas/nrocuentas',
            ChangeStatus: url + 'finanzas/nrocuentas/destroy/', //completar con el ID
            Delete: url + 'finanzas/nrocuentas/delete/', //completar con el ID
        }
    },
    Apps:
    {
        GetAppUser : url + 'apps/getAppUser/', //completar con el ID de la app
    },
    Tourism:
    {
        GetToAdd: url + 'tourism/ally/to_add',
        Add: url + 'tourism/ally/add',
        GetAll: url + 'tourism/ally/all',
        GetPlace: url + 'tourism/ally/place/', // completar con el uuid
        Update: url + 'tourism/ally/place/', // completar con el uuid
        ChangeStatus: url + 'tourism/ally/changestatus/', // completar con el uuid
    },
    Reservations:
    {
        GetToBeApproved: url + 'comercios/reservations',
        Approve: url + 'comercios/reservations/approve',
        Approved: url + 'comercios/reservations/approved',
        historical: url + 'comercios/reservations/historical',
    },
    Reviews:
    {
        GetAll: url + 'comercios/reviews'
    },
    Orders:
    {
        GetOrders: url + 'pedidos/comercio/orders',
        GetOrdersDetails: url + 'pedidos/comercio/orders/details/', //completar con el codigo del pedido
        UpdateOrderStatus : url + 'pedidos/comercio/orderstatus',
    },
    Analitycs:{
        Views: url + 'analytics/views/', //complete with type
    },
    Entities:{
        SearchEntities: url + 'entities/find'
    }
    /**
     * CompanySignup : url + 'companysignup',
     */
};


export default CompanyRoutes;
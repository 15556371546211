import { useContext, useEffect, useReducer, useState } from "react"
import { Backdrop, Box, Button, Grid, IconButton, List, ListItem, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useTheme } from '@mui/material/styles';
import { MobileStepper } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCheckToSlot, faClose, faEye, faEyeSlash, faMinusCircle, faPlusCircle, faTimesCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { LoadingButton } from "@mui/lab";
import { IssueTicketsAtBoxOffice, SectionList } from "../../../../../controllers/company/EventsController";
import { GetAllBankAccounts } from "../../../../../controllers/company/AccountNumbersController";
import UserContext from "../../../../../navigation/context/userContext";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { motion } from "framer-motion";
import qud_logo from '../../../../../assets/images/logos/QUD212121.png';
import QUDProgress from "../../../../../components/progress";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import ReactInputVerificationCode from "react-input-verification-code";
import { isNumeric } from "../../../../../controllers/AuxController";
import { printWithRawBT, printWithWebBluetooth } from "../../../../../controllers/PrinterController";
import PrintWithQZTray from "../../../../../components/QZPrint";



const initialState = {
    nombre : '',        errorNombre : false,
    apellido : '',      errorApellido : false,
    documento_tipo:'V',
    documento:'',       errorDocumento: false,
    prefijo:'',
    telefono:'',        errorTelefono: false,
    email:'',           errorEmail: false,
    
      
    isLoading : false,
    open : false,
    result : false, message : '', title : '',

    ciudades: [],
    categorias: [],

    sections: [], tickets:[], errorTickets:false,
    subtotal:0,
    iva:0,
    comision:0,
    total:0,    errorTotal: false,

    paymentMethods: [],
    payments: [],
    bankAccounts: [],       bankAccountSelected:null,
    bankAccountsAux:[],
    paymentMethod: null,    errorPaymentMethod: false,
    amount:'',              errorAmount: false,
    reference:'',           errorReference: false,
    totalPaid: 0,           errorTotalPaid: false,
    showPayments: false,
    ticketsIssued:[]

}
  
function reducer( state, { field, value })
{
    return{
        ...state,
        [field] : value
    }
}



export default function IssueTicket(props)
{
    const {userData} = useContext(UserContext);
    let { id } = useParams();
    const theme = useTheme();
    const [event,setEvent] = useState(props.event);
    const [country,setCountry] = useState(props.country);
    const [exchangeRate,setExchangeRate] = useState(props.exchangeRate);
    const [quantity,setQuantity] = useState(0);

    const [isComplimentary,setIsComplimentary] = useState(false);
    const [complimentaryCode,setComplimentaryCode] = useState('');
    const [complimentaryCodeError,setComplimentaryCodeError] = useState(false);

    const [activeStep, setActiveStep] = useState(0);

    const [state,setState] = useReducer(reducer,initialState);

    const {
        nombre,errorNombre, apellido, errorApellido, documento_tipo, documento, errorDocumento, prefijo, telefono, errorTelefono, email, errorEmail,

        sections, subtotal, iva, total, errorTotal, comision,
        paymentMethods, payments, bankAccounts, bankAccountsAux, bankAccountSelected,
        paymentMethod, errorPaymentMethod, amount, errorAmount, reference, errorReference, totalPaid, errorTotalPaid,
        showPayments,

        isLoading, result, message, title,

        ticketsIssued
        
    } = state;

    //const _ticketsArray = [];

    useEffect(()=>{
        handleGetSections();
        handleGetPaymentsMethods();
    },[]);

    const onChange = (e) => {
        if(e.target.name === "documento" || e.target.name === "telefono")
            setState({field : e.target.name, value : e.target.value.replace(/[^\d,]/g, '')});
        else
        setState({field : e.target.name, value : e.target.value});
    }

    const handleGetSections = async () => {
        let _boletos = [];
        let response = await SectionList(id);
        if(response.success === true)
        {
            //setState({field:'sections',value:response.data});
            await Promise.all( response.data.map(item=>
                _boletos.push({
                    evento_seccion_id: item.evento_seccion_id,
                    uuid:item.uuid,
                    nombre: item.nombre,
                    descripcion: item.descripcion,
                    precio: item.precio,
                    cantidad: 0, //poner en 0
                    evento_id: item.evento_id,
                    lista_asientos:null
                })
            ));

            //setState({field:'sections',value:response.data});
            setState({field:'sections',value:_boletos});

        }
    }


    /**--------------------------------------------------
     * Para manejar el paso a paso                      |
     * ------------------------------------------------*/
    const handleNext = () => {
        if(activeStep === 0 && formVerificationStep0() !== 0)
            return;
        
        if(activeStep === 1 && formVerificationStep1() !== 0)
            return;

        if(activeStep === 2 && formVerificationStep2() !== 0)
        {
            return;
        }
        if(activeStep === 2 && formVerificationStep2() === 0)
        {
            handleSubmit();
        }
            
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    /**---------------------------------------------------------------
     *      VERIFICACION DE FORMULARIOS
     *--------------------------------------------------------------*/
    const formVerificationStep0 = () => {
        let ban = 0;

        if(nombre === '')
        {
            setState({field:'errorNombre',value:true});
            ban = 1;
        }

        if(apellido === '')
        {
            setState({field:'errorApellido',value:true});
            ban = 1;
        }

        if(country.doc_obligatorio === 1 && documento === '')
        {
            setState({field:'errorDocumento',value:true});
            ban = 1;
        }

        if(telefono === '')
        {
            setState({field:'errorTelefono',value:true});
            ban = 1;
        }

        if(email === '')
        {
            setState({field:'errorEmail',value:true});
            ban = 1;
        }

        return ban;
    }

    const formVerificationStep1 = () => {
        let ban = 0;
        //return ban; //quitar
        if(total === 0)
        {
            setState({field:'errorTotal',value:true});
            ban = 1;
        }
        else
        {
            setState({field:'errorTotal',value:false});
            ban = 0;
        }
        
        return ban;
    }

    const formVerificationStep2 = () => {
        let ban = 0;
        //return ban; //quitar
        if(isComplimentary === false)
        {
            if(totalPaid < total)
                {
                    setState({field:'errorTotalPaid',value:true});
                    ban = 1;
                }
        }
        else
        {
            if(isNumeric(complimentaryCode) === false)
            {
                setComplimentaryCodeError(true);
                ban = 1;
            }
        }
        
        return ban;
    }

    const formVerificationRecordPayment = () => {
        let ban = 0;
        //return ban = 0; //quitar
        if(paymentMethod === null)
        {
            setState({field:'errorPaymentMethod',value:true});
            ban = 1;
        }

        if(amount === '')
        {
            setState({field:'errorAmount',value:true});
            ban = 1;
        }

        if(reference === '')
        {
            setState({field:'errorReference',value:true});
            ban = 1;
        }
        
        return ban;
    }




    /**---------------------------------------------------------------
     *      GESTION DEL "CARRITO DE COMPRAS"                        |
     *--------------------------------------------------------------*/
    const handleShoppingCart = async (_uuid,_operator) => {
        const newState = sections.map(item => {
            if(item.uuid === _uuid)
            {
                let _cantidad = item.cantidad;

                if(_operator === '+')
                    _cantidad = _cantidad + 1;
                else
                {
                    if(_cantidad > 0)
                        _cantidad = _cantidad - 1;
                }

                //item.cantidad = _cantidad;
                let _lista_asientos = [];
                for(let i=0;i<_cantidad;i++)
                {
                    _lista_asientos.push({fil:item.nombre});
                }

                return {
                    ...item,
                    cantidad:_cantidad,
                    lista_asientos:_lista_asientos
                }
            }

            return item;
        });

        setState({field:'sections',value:newState});
    }

    const handleTotals = async () => {
        let _subtotal = 0;
        let _iva = 0;
        let _total = 0;
        let _comision = 0;

        await Promise.all(sections.map(item=>{
            _subtotal += (item.cantidad * item.precio);
        }));

        _comision = parseFloat(_subtotal*(parseFloat(event.comision)/100));
        //_total = parseFloat(_subtotal) + (_subtotal*(parseFloat(event.comision)/100));
        _total = _subtotal + _comision;

        setState({field:'subtotal',value:_subtotal});
        setState({field:'comision',value:_comision});
        setState({field:'total',value:_total});

    }

    useEffect(()=>{
        handleTotals();
        formVerificationStep1();
    },[sections,total]);


    /**---------------------------------------------------------------
     *                      GESTION DE PAGOS                        |
     *--------------------------------------------------------------*/
    const handleGetPaymentsMethods = async () => {
        let response = await GetAllBankAccounts();
        if(response.success === true)
        {
            setState({field:'paymentMethods',value: response.data.payment_methods});
            setState({field:'bankAccounts',value: response.data.accounts});
            setState({field:'paymentMethod',value: response.data.payment_methods[0]});
        }
    }

    const handleRecordPayment = async () => {
        if(formVerificationRecordPayment() === 0)
        {
            setState({field:'payments',value:[...payments,
                {
                    uuid: bankAccountSelected.uuid,
                    paymentMethod: bankAccountSelected.payment_method,
                    accountName: bankAccountSelected.description,
                    amount: parseFloat(amount),
                    reference: reference,
                    id_numero_cuenta: bankAccountSelected.id
                }
            ]});
    
            setState({field:'totalPaid',value:totalPaid+parseFloat(amount)});
    
            handleClearPayment();
        }
    }

    const handlePaymentMethodSelected = async () => {
        let _aux = await Promise.all(bankAccounts.filter(item=>{
            return item.payment_method === paymentMethod.nombre
        }));
        
        setState({field:'bankAccountsAux',value:_aux});
        setState({field:'bankAccountSelected',value:_aux[0]})
    }

    const handleBankAccountSelected = async (item) => {
        setState({field:'bankAccountSelected',value:item})
    }

    const handleShowPayments = () => {
        
    }

    useEffect(()=>{
        handlePaymentMethodSelected();
    },[paymentMethod]);

    const handleClearPayment = () => {
        //setState({field:'paymentMethod',value:null});
        setState({field:'amount',value:''});
        setState({field:'reference',value:''});
    }

    useEffect(()=>{
        
    },[payments]);


    const handleSubmit = async () => {
        setState({field:'isLoading',value:true});

        let document_type = '';
        if(country.doc_obligatorio === 1)
            document_type = documento_tipo;

        let cliente = {
            nombre: nombre,
            apellido: apellido,
            documento_tipo: document_type,
            documento: documento,
            correo:email,
            prefijo:prefijo,
            telefono: telefono,
            profesion:''
        };

        //cliente = JSON.stringify(cliente);

        let detalles = await Promise.all(sections.filter(item => item.cantidad > 0 ));
        //detalles = JSON.stringify(detalles);

        const monto = parseFloat(subtotal);
        
        let response = await IssueTicketsAtBoxOffice(
            'merchant',
            event.id_evento,
            userData.entity.id,
            userData.entity.uuid,
            cliente,
            monto,
            comision,
            detalles,
            payments,null,[],null,
            isComplimentary,
            complimentaryCode
        );
        //console.log(response)
        if(response.success === true)
        {
            setState({field:'result',value:true});
            setState({field:'title',value:'Operación Exitosa'});
            setState({field:'message',value:response.message});

            //Para impresion
            console.log('TICKEST PARA IMPRESION ===>',response.data.tickets)
            setState({field:'ticketsIssued',value:response.data.tickets});
            
            console.log('Operación exitosa...')
        }
        else
        {
            setState({field:'result',value:false});
            setState({field:'title',value:'Operación Fallida'});
            setState({field:'message',value:response.message.message});
        }

        setState({field:'isLoading',value:false});
    }

    const handleFinalizar = () => {
        setState({field:'payments',value:[]});
        
        setState({field:'nombre',value:''});
        setState({field:'apellido',value:''});
        setState({field:'documento',value:''});
        setState({field:'telefono',value:''});
        setState({field:'email',value:''});

        setState({field:'total',value:0});
        setState({field:'totalPaid',value:0});

        setIsComplimentary(false);
        setComplimentaryCode('······');
        setComplimentaryCodeError(false);

        handleGetSections();
        handleGetPaymentsMethods();

        setActiveStep(0);
    }

    useEffect(()=>{
        if(complimentaryCode !== '······')
        {
            if(isNumeric(complimentaryCode) === true)
                setComplimentaryCodeError(false);
        }
    },[complimentaryCode]);


    /**-------------------------------------------------------------
     *                      IMPRESION DE TICKETS                    |
     *--------------------------------------------------------------*/
    const handlePrintRBT = () => {
        printWithRawBT(userData.user.apellido,event.nombre,ticketsIssued);
    }

    const handlePrintWeb = () => {
        printWithWebBluetooth(userData.user.apellido,event.nombre,ticketsIssued)
    }


    return(
        <Grid container spacing={3} justifyContent={'center'}>
            <QUDProgress open={isLoading}/>
            <Grid item xs={12} sm={12} md={12} lg={10} xl={10}>
                <Paper elevation={4}>
                    <Typography variant="h6" mb={2}>Emitir Boletos</Typography>
                    <Grid container spacing={3} justifyContent={'center'}>
                        {
                            activeStep === 0 &&
                            <>
                                <Grid item lg={6} xl={6}>
                                    <Typography sx={{textAlign:'center',mb:2}} variant="h6">Datos del Cliente</Typography>
                                    <Box sx={{'& .MuiTextField-root': { m: 1 }}}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={6} lg={6}>
                                                <TextField
                                                    fullWidth
                                                    label={event.dirigido_a === 0 ? 'Nombre' : 'Nombre Legal'}
                                                    name='nombre'
                                                    id='nombre'
                                                    required
                                                    value={nombre}
                                                    onChange = { onChange }
                                                    onBlur={ () => (nombre === '' ? setState({field:'errorNombre',value:true}) : setState({field:'errorNombre',value:false}) ) }
                                                    error = {errorNombre}
                                                    helperText={errorNombre && 'Ingrese el nombre del cliente'}
                                                />
                                            </Grid>
                                            <Grid item xs={6} lg={6}>
                                                <TextField
                                                    fullWidth
                                                    label={event.dirigido_a === 0 ? 'Apellido' : 'Nombre Comercial'}
                                                    name='apellido'
                                                    id='apellido'
                                                    required
                                                    value={apellido}
                                                    onChange = { onChange }
                                                    onBlur={ () => (apellido === '' ? setState({field:'errorApellido',value:true}) : setState({field:'errorApellido',value:false}) ) }
                                                    error = {errorApellido}
                                                    helperText={errorApellido && 'Ingrese el apellido del cliente'}
                                                />
                                            </Grid>
                                            {
                                                country.doc_obligatorio === 1 &&
                                                <>
                                                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                                                        <TextField
                                                            fullWidth
                                                            id="documento_tipo"
                                                            name="documento_tipo"
                                                            label="Documento"
                                                            select
                                                            value={documento_tipo}
                                                            onChange={ onChange }
                                                        >
                                                            <MenuItem value='V'>V</MenuItem>
                                                            <MenuItem value='E'>E</MenuItem>
                                                        </TextField>
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                                        <TextField
                                                            fullWidth
                                                            label={event.dirigido_a === 0 ? 'Número de Cédula' : 'Número de RIF'}
                                                            name='documento'
                                                            id='documento'
                                                            required
                                                            value={documento}
                                                            onChange = { onChange }
                                                            onBlur={ () => (documento === '' ? setState({field:'errorDocumento',value:true}) : setState({field:'errorDocumento',value:false}) ) }
                                                            error = {errorDocumento}
                                                            helperText={errorDocumento && 'Ingrese la Cédula /RIF del cliente'}
                                                        />
                                                    </Grid>
                                                </>
                                            }
                                            

                                            {/**Telefono */}
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <PhoneInput
                                                    country={country.simbolo}
                                                    specialLabel="Número de Teléfono"
                                                    inputStyle={{width:'100%',marginLeft:7}}
                                                    value={telefono}
                                                    onChange={(e)=>setState({field:'telefono',value:e})} // passed function receives the phone value
                                                    onBlur={ () =>  (setState({field:'errorTelefono',value: telefono.length < 11?true:false})) }
                                                />
                                                {
                                                    errorTelefono &&
                                                    <Typography variant="caption" color={'error.main'}>El número de teléfono es inválido</Typography>
                                                }
                                            </Grid>

                                           
                                            <Grid item xs={12} lg={12}>
                                                <TextField
                                                    fullWidth
                                                    label='Correo electrónico'
                                                    name='email'
                                                    id='email'
                                                    required
                                                    value={email}
                                                    onChange = { onChange }
                                                    onBlur={ () => (email === '' ? setState({field:'errorEmail',value:true}) : setState({field:'errorEmail',value:false}) ) }
                                                    error = {errorEmail}
                                                    helperText={errorEmail && 'Ingrese correo electrónico del cliente'}
                                                />
                                            </Grid>
                                        </Grid>                        
                                    </Box>
                                </Grid>
                            </>
                        }

                        {
                            activeStep === 1 &&
                            <>
                                <Grid item lg={12} xl={12}>
                                    <Box sx={{'& .MuiTextField-root': { m: 1 }}}>
                                        <Grid container spacing={3}>
                                            <Grid item lg={6}>
                                                {
                                                    sections &&
                                                    sections.map((item)=>{
                                                        return(
                                                            <List>
                                                                <ListItem>
                                                                    <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'100%'}}>
                                                                        <Typography>
                                                                            {item.nombre} <span style={{color:'#6e6e6e'}}>(${parseFloat(item.precio).toFixed(2)} c/u)</span>
                                                                        </Typography>
                                                                        <Box sx={{display:'flex',flexDirection:'row'}}>
                                                                            <Button onClick={()=>handleShoppingCart(item.uuid,'-')}>
                                                                                <FontAwesomeIcon icon={faMinusCircle}/>
                                                                            </Button>
                                                                            <Typography>&nbsp;{item.cantidad}&nbsp;</Typography>
                                                                            <Button onClick={()=>handleShoppingCart(item.uuid,'+')}>
                                                                                <FontAwesomeIcon icon={faPlusCircle}/>
                                                                            </Button>
                                                                        </Box>
                                                                    </Box>
                                                                    
                                                                </ListItem>
                                                            </List>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                            <Grid item lg={6} xl={6}>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell rowSpan={1} />
                                                            <TableCell align="right" colSpan={1} sx={{fontWeight:'bold'}}>Subtotal</TableCell>
                                                            <TableCell align="right" colSpan={2}>{subtotal}</TableCell>
                                                        </TableRow>
                                                        <TableRow>
                                                            <TableCell/>
                                                            <TableCell align="right" sx={{fontWeight:'bold'}}>Total</TableCell>
                                                            <TableCell/>
                                                            <TableCell align="right">{parseFloat(subtotal+comision).toFixed(2)}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                                {
                                                    errorTotal &&
                                                    <Typography color='error'>Debe seleccionar al menos un boleto</Typography>
                                                }
                                            </Grid>
                                        </Grid>                        
                                    </Box>
                                </Grid>
                            </>
                        }

                        {/**Pagos */}
                        {
                            activeStep === 2 &&
                            <>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <Grid container spacing={3}>
                                        <Grid item lg={6} xl={6} justifyContent={'center'}>
                                            <Typography textAlign={'center'}>
                                                USD &nbsp;
                                                {parseFloat(totalPaid).toFixed(2)} /&nbsp;
                                                <span style={{fontWeight:'bold'}}>{parseFloat(total).toFixed(2)}</span>
                                                &nbsp;
                                                <IconButton onClick={()=>setState({field:'showPayments',value:!showPayments})}>
                                                    {
                                                        totalPaid < total &&
                                                        (
                                                            showPayments === true
                                                            ?<FontAwesomeIcon style={{fontSize:17}} icon={faEyeSlash}/>
                                                            :<FontAwesomeIcon style={{fontSize:17}} icon={faEye}/>
                                                        )
                                                    }
                                                    
                                                </IconButton>
                                            </Typography>
                                            <Typography textAlign={'center'}>VES {parseFloat(totalPaid*exchangeRate).toFixed(2)} / {parseFloat(total*exchangeRate).toFixed(2)}</Typography>
                                        </Grid>
                                        <Grid item lg={6} xl={6} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                                            <Button variant='outlined' onClick={()=>setIsComplimentary(!isComplimentary)}>
                                                {
                                                    isComplimentary === true
                                                    ?<Typography>Cancelar emision de cortesia</Typography>
                                                    :<Typography>Emitir entradas de cortesia</Typography>
                                                }
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    
                                </Grid>

                                {
                                    isComplimentary === true
                                    ?
                                    <Grid item lg={6} xl={6}>
                                        <Typography variant="h6" textAlign={'center'} gutterBottom>Para emitir entradas de cortesia por favor ingrese el codigo secreto establecido para tal fin</Typography>
                                        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                                            <ReactInputVerificationCode
                                                type="password"
                                                length={6}
                                                onChange = {(e)=>setComplimentaryCode(e)}
                                                //onCompleted={()=>console.log('isNumeric value ===> ',isNumeric(complimentaryCode))}
                                            />
                                        </Box>
                                        {
                                            complimentaryCodeError &&
                                            <Typography variant="h6" color={'error.main'} textAlign={'center'} gutterBottom>
                                                Complete el código solo con números
                                            </Typography>
                                        }
                                    </Grid>
                                    :
                                    totalPaid >= total || showPayments === true
                                    ?
                                    <Grid lg={6} xl={6} sx={{display:'flex', width:'100%',flexDirection:'row',justifyContent:'center',paddingLeft:3}}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Forma de pago</TableCell>
                                                    <TableCell>Referencia</TableCell>
                                                    <TableCell align="right">Monto</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    payments.map((item)=>
                                                    <TableRow>
                                                        <TableCell>{item.paymentMethod}</TableCell>
                                                        <TableCell>{item.reference}</TableCell>
                                                        <TableCell align="right">{parseFloat(item.amount).toFixed(2)}</TableCell>
                                                    </TableRow>
                                                    )
                                                }
                                            </TableBody>
                                        </Table>
                                    </Grid>
                                    :
                                    <>
                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <Box sx={{'& .MuiTextField-root': { m: 1 }}}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} lg={12}>
                                                        <TextField
                                                            fullWidth
                                                            select
                                                            autoFocus={true}
                                                            id="paymentMethod"
                                                            name='paymentMethod'
                                                            label="Forma de Pago"
                                                            variant="outlined"
                                                            value = {paymentMethod}
                                                            onChange = { onChange }
                                                            onBlur={ () => (paymentMethod === null ? setState({field:'errorPaymentMethod',value:true}) : setState({field:'errorPaymentMethod',value:false}) ) }
                                                            error = {errorPaymentMethod}
                                                            helperText={errorPaymentMethod && 'Seleccione una forma de pago'}
                                                        >
                                                            <MenuItem value={null}>Seleccione una forma de pago</MenuItem>
                                                            {
                                                                paymentMethods &&
                                                                paymentMethods.map(item=>
                                                                    <MenuItem key={item.id} value={item}>{item.nombre}</MenuItem>
                                                                )
                                                            }
                                                        </TextField>
                                                    </Grid>
                                                    
                                                    {/**Se mapea bankAccountsAux */}
                                                    {
                                                        (bankAccountsAux && paymentMethod) &&
                                                        bankAccountsAux.map(item=>
                                                            bankAccountSelected && item.uuid === bankAccountSelected.uuid 
                                                            ?
                                                            <Grid
                                                                key={item.uuid} item xs={12} sm={6} md={4} lg={6} xl={6}
                                                                onClick={()=>handleBankAccountSelected(item)}
                                                            >
                                                                <Paper sx={{border:'solid',borderWidth:1,borderColor:'success.light',cursor:'pointer',bgcolor:'success.lightTransparency',color:'success.dark'}}>
                                                                    <Typography>{item.bank} - {item.routing_number}</Typography>
                                                                    <Typography>{userData.user.documento}</Typography>
                                                                    <Typography>{item.account_number}</Typography>
                                                                </Paper>
                                                            </Grid>
                                                            :
                                                            <Grid
                                                                key={item.uuid} item xs={12} sm={6} md={4} lg={6} xl={6}
                                                                onClick={()=>handleBankAccountSelected(item)}
                                                            >
                                                                <Paper sx={{cursor:'pointer'}}>
                                                                    <Typography>{item.banco} - {item.numero_ruta}</Typography>
                                                                    <Typography>{userData.user.documento}</Typography>
                                                                    <Typography>{item.numero_cuenta}</Typography>
                                                                </Paper>
                                                            </Grid>
                                                        )
                                                    }
                                                </Grid>                        
                                            </Box>
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                            <Box sx={{'& .MuiTextField-root': { m: 1 }}}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={12} lg={12}>
                                                        <TextField
                                                            fullWidth
                                                            label='Monto ($)'
                                                            name='amount'
                                                            id='amount'
                                                            required
                                                            value={amount}
                                                            onChange = { onChange }
                                                            onBlur={ () => (amount === '' ? setState({field:'errorAmount',value:true}) : setState({field:'errorAmount',value:false}) ) }
                                                            error = {errorAmount}
                                                            helperText={errorAmount && 'Ingrese el monto en $ del pago'}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} lg={12}>
                                                        <TextField
                                                            fullWidth
                                                            label='Referencia'
                                                            name='reference'
                                                            id='reference'
                                                            required
                                                            value={reference}
                                                            onChange = { onChange }
                                                            onBlur={ () => (reference === '' ? setState({field:'errorReference',value:true}) : setState({field:'errorReference',value:false}) ) }
                                                            error = {errorReference}
                                                            helperText={errorReference && 'Ingrese la referencia del pago'}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} mt={2} >
                                                        <LoadingButton
                                                            style={{padding:15}}
                                                            fullWidth
                                                            variant="contained"
                                                            onClick={()=>handleRecordPayment()}
                                                        >
                                                            <Typography>
                                                                Registrar Pago
                                                            </Typography>
                                                        </LoadingButton>
                                                    </Grid>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                    </>
                                }
                                
                            </>
                        }

                        {
                            activeStep === 3 &&
                            <>
                                <Grid item lg={12} xl={12}>
                                    {
                                        isLoading === true
                                        ?<QUDProgress/>
                                        :
                                        <Grid container spacing={3} justifyContent={'center'}>
                                            <Grid item lg={4} xl={4} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
                                                {
                                                    result === true
                                                    ?<FontAwesomeIcon style={{fontSize:50,color:'#2E7D32'}} icon={faCheckCircle}/>
                                                    :<FontAwesomeIcon style={{fontSize:50,color:'#F33232'}} icon={faTimesCircle}/>
                                                }
                                                <Typography variant="h6" sx={{fontWeight:'bold'}}>¡{title}!</Typography>
                                                <Typography variant="h6" textAlign={'center'}>{message}</Typography>
                                                
                                                <Button
                                                    sx={{mt:5}}
                                                    variant="contained"
                                                    onClick={()=>handlePrintRBT()}
                                                >
                                                    Imprimir (Android)
                                                </Button>

                                                <Button
                                                    sx={{mt:2}}
                                                    variant="contained"
                                                    onClick={()=>handlePrintWeb()}
                                                >
                                                    Imprimir (Windows - Bluetooth)
                                                </Button>

                                                <PrintWithQZTray
                                                    merchant={userData.user.apellido}
                                                    event={event.nombre}
                                                    tickets={ticketsIssued}
                                                />

                                                <Button variant="contained" color="primary" sx={{mt:5}}
                                                    onClick={()=>handleFinalizar()}
                                                >
                                                    <Typography>Finalizar</Typography>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    }
                                    
                                </Grid>
                            </>
                        }
                        
                        {/**Stepper */}
                        <Grid item xs={12} sm={12} md={12} lg={12} lx={12}>
                            <MobileStepper
                                variant="progress"
                                steps={4}
                                position="static"
                                activeStep={activeStep}
                                sx={{ maxWidth: '100%', flexGrow: 1 }}
                                nextButton={
                                    <Button size="small" onClick={handleNext} disabled={activeStep === 3}>
                                        {
                                            activeStep === 2 ? 'Emitir' : 'Continuar'
                                        }
                                        {theme.direction === 'rtl' ? (
                                            <KeyboardArrowLeft />
                                        ) : (
                                            <KeyboardArrowRight />
                                        )}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                                    {theme.direction === 'rtl' ? (
                                        <KeyboardArrowRight />
                                    ) : (
                                        <KeyboardArrowLeft />
                                    )}
                                    Volver
                                    </Button>
                                }
                            />
                        </Grid>
                    </Grid>
                        
                </Paper>
            </Grid>
        </Grid>
    )
}
import { useState } from "react"
import { searchEntities } from "../../controllers/EntitiesController";
import { Button, Dialog, DialogContent, Grid, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { LoadingButton } from "@mui/lab";

const EntityFinder = ({title='Buscar entidad',onSelectEntity,isOpen,onClose}) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading,setIsLoading] = useState(false);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        console.log('hola')
    }

    const handleSearch = async () => {
        if(!searchTerm) return;

        setIsLoading(true);
        
        try{
            console.log('el searcht term es => '+searchTerm);
            let response = await searchEntities(searchTerm);
            console.log(response); 
        }
        catch(error){
            console.error("Error fetching entities:", error);
            setSearchResults([]);
        }
        finally {
            setIsLoading(false);
        }
    }

    
    return(
        
        <Dialog
            open={isOpen}
            fullWidth={true}
            maxWidth={'sm'}
            onClose={onClose}
        >
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Typography variant="h6">
                            {title}
                        </Typography>
                    </Grid>

                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                        <TextField
                            fullWidth
                            label='Termino de busqueda'
                            onChange={handleSearchChange}
                        />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                        <Tooltip title="Click para buscar">
                            <IconButton onClick={() => handleSearch()} loading={isLoading}>
                                <FontAwesomeIcon icon={faSearch}/> 
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )

}

export default EntityFinder;
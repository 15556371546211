import React, { useContext, useEffect, useState } from 'react';

import { useHistory, Link } from 'react-router-dom';

import MUIDataTable from "mui-datatables";
import { Box, Rating, Tooltip, Typography } from '@mui/material';
import { Chip } from '@mui/material';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEdit, faPlay, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IconButton } from '@mui/material';
import UserContext from '../../../navigation/context/userContext';
import { GetProductos } from '../../../controllers/company/ProductsController';
import CompanyRoutes from '../../../apiRoutes/CompanyRoutes';
import { imagesURL } from '../../../apiRoutes/apiBase';
import { POST } from '../../../controllers/ConsumeApiController';
import { useTranslation } from 'react-i18next';

//export default class TablaProductos extends Component
export default function ReviewsDataTable(props)
{
    const {t, i18n} = useTranslation();

    const {userData,setUserData} = useContext(UserContext)
    const [reviews,setReviews] = useState(props.reviews);

    const columns = [
        {
            name : 'score',
            label : `${t('reviews.score')}`,
            options : 
            {
                customBodyRenderLite : (dataIndex) => {
                    //let id = subCategorias[dataIndex].idSubCategoria;
                    return(
                        <Box>
                            <Rating value={reviews[dataIndex].score}/>
                        </Box>
                    );
                },
                filter:false,
                searchable:true
            }
        },
        {
            name:'type',
            label:`${t('reviews.type')}`,
        },
        {
            name : 'comment',
            label : `${t('reviews.comment')}`,
            
            options:{
                filter:false,
                searchable:true
            }
        },
        {
            name : 'created_at',
            label : `${t('reviews.date')}`,
            
            options:{
                filter:false,
                searchable:true
            }
        }
    ];


    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
      };


    return(
        <MUIDataTable
            title={`${t('reviews.list')}`}
            data={reviews}
            columns={columns}
            options={options}
        />
    )
}
import React, { useEffect, useMemo, useReducer, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { BrowserRouter as Router, Redirect, useHistory, useLocation } from 'react-router-dom';

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';



import AuthContext from './navigation/context/authContext';
import UserContext from './navigation/context/userContext';
import lightTheme from './themes/lightTheme';
import darkTheme from './themes/darkTheme';
import ColorModeContext from './themes/colorModeContext';
import WebSiteNavigation from './navigation/WebSiteNavigation';
import { login, login2 } from './controllers/AuthController';
import ClientNavigation from './navigation/clientNavigation';
import CompanyNavigation from './navigation/companyNavigation';
import AdminNavigation from './navigation/adminNavigation';

import ReactGA from 'react-ga4';


import { SUSE } from '@fontsource/suse';
import { HelmetProvider } from 'react-helmet-async';

export default function App()
{
    const [userData,setUserData] = useState({
        token: localStorage.getItem("AccessToken")
            ? localStorage.getItem("AccessToken")
            : undefined,
        user: localStorage.getItem("UserAccount")
            ? JSON.parse(localStorage.getItem("UserAccount"))
            : undefined,
        entity: localStorage.getItem("Entity")
            ? JSON.parse(localStorage.getItem("Entity"))
            : undefined,
    });


    function loadGoogleMapsApi(apiKey) {
        console.log('INICIALIZACON GOOGLE MAPS');
        if (!window.google) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
        }
    }

    useEffect(() => {
        loadGoogleMapsApi('AIzaSyB1vPO8CLBmZc6ba1pRhmhzbz_zZe6QImE');
    }, []);
    

    const history = useHistory();
    

    const [isOpen,setIsOpen] = useState(false);
    const [errorMsg,setErrorMsg] = useState('');

    const [mode, setMode] = React.useState('light');
    const colorMode = React.useMemo(
        () => ({
        toggleColorMode: () => {
            setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
        },
        }),
        [],
    );

    useEffect(()=>{
        window.scrollTo(0,0);
    })


    const theme = React.useMemo(
        () => 
        createTheme(
            {
                typography:
                {
                    //fontFamily:['koho','koho Bold','koho Italic','sans-serif',].join(','),
                    fontFamily:['SUSE', 'SUSE Bold', 'SUSE Italic','sans-serif'].join(',')
                }
            },
            mode === 'light' ? lightTheme : darkTheme,
        )
    )

    const [state, dispatch] = useReducer(
        (prevState, action) => 
        {
            switch (action.type) 
            {
                case 'RESTORE_TOKEN':
                    return {
                        ...prevState,
                        userToken: action.token,
                        isLoading: false,
                    };
                case 'SIGN_IN':
                    return {
                        ...prevState,
                        isSignout: false,
                        userToken: action.token,
                    };
                case 'SIGN_OUT':
                    return {
                        ...prevState,
                        isSignout: true,
                        userToken: null,
                    };
                default:
                    return;
            }
        },
        {
            isLoading: false,
            isSignout: false,
            userToken: null,
        }
    );


    //Se busca en el local storage los token necesarios para que el usuario pueda usar la app web
    useEffect(() => {

        const bootstrapAsync = async () => {
            let userToken;
            try
            {
                userToken = await localStorage.getItem('AccessToken');
                console.log(userData)
            }
            catch(error)
            {

            }

            dispatch({
                type: 'RESTORE_TOKEN',
                token: userToken,
            })
        };

        bootstrapAsync();
    },[]);


    const authContext = useMemo(
        () => ({
            signIn: async data => 
            {
                try
                {
                    let email = data.email;
                    let password = data.password;

                    let response = null;

                    if(data.oAuth === true)
                        response = await login2(email,data.name,data.provider,data.uid);
                        //response = await login2(email);
                    else
                        response = await login(email,password);
                    
                    if(response.success === true)
                    {
                        
                        localStorage.setItem('AccessToken',response.data.token);
                        localStorage.setItem('UserAccount',JSON.stringify(response.data.user));
                        localStorage.setItem('Entity',JSON.stringify(response.data.entity));
                        
                        await setUserData({
                            token : response.data.token,
                            user : response.data.user,
                            entity : response.data.entity
                        });

                        dispatch({ type: 'SIGN_IN', userToken: response.data.token });
                        
                    }

                    if(response.success === false)
                    {
                        setIsOpen(true);
                        setErrorMsg(response.message);
                    }
                }
                catch(error)
                {
                    setIsOpen(true);
                    setErrorMsg(error);
                }
            },
            signOut: () =>
            {
                console.log('Cerrando Sesion');
                localStorage.removeItem('UserAccount');
                localStorage.removeItem('AccessToken');
                setUserData({
                    token: undefined,
                    user: undefined,
                    entity: undefined
                })
                dispatch({ type: 'SIGN_OUT' });
            },
            signUp: async data =>
            {
            }
        }),
        []
    );

    if(state.isLoading)
    {
        return <CircularProgress/>
    }


    return(
        <HelmetProvider>
            <ColorModeContext.Provider value={colorMode}>
                <ThemeProvider theme={theme}>
                    <Router >
                        <Dialog
                            open = {isOpen}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle>Error</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    {errorMsg}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={()=>setIsOpen(false)} color="primary" autoFocus>
                                    Aceptar
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <AuthContext.Provider value={authContext}>
                            <UserContext.Provider value={{ userData, setUserData }}>
                                {
                                    state.userToken === null
                                    ? <WebSiteNavigation/>
                                    : 
                                        userData.user.type === 'company'
                                        ?<CompanyNavigation/>
                                        :<ClientNavigation/>
                                        
                                }
                                {/*
                                    state.userToken === null
                                    ?
                                    //<AuthNavigation/>
                                    <WebSiteNavigation/>
                                    :
                                        userData.user.idTipoUsuario === 1
                                        ?<AdminNavigation/>
                                        :
                                            (userData.user.idTipoUsuario === 2 || userData.user.idTipoUsuario === 4)
                                            ?<CompanyNavigation/>
                                            :<ClientNavigation/>
                                */}
                            </UserContext.Provider>
                        </AuthContext.Provider>
                    </Router>
                </ThemeProvider>
            </ColorModeContext.Provider>
        </HelmetProvider>
    );
}


/*import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/
import {Box, Container, Grid, useTheme} from '@mui/material';

import { Payment } from '@mercadopago/sdk-react';
import { initMercadoPago } from '@mercadopago/sdk-react'
import { useEffect, useState } from 'react';
import { create_preference_id, process_payment } from '../../controllers/MercadoPagoController';
//initMercadoPago('APP_USR-a17d576f-e11d-4491-b101-ed101d33007b');
initMercadoPago('TEST-57729305-38a2-4968-904b-5876d8c24176')


export default function MercadoPagoColombia({buttonText='Pagar Ahora', title,amount,handleOnSubmit})
{
    const theme = useTheme();
    const [preferenceID,setPreferenceID] = useState('');
    const [isLoading,setIsLoading] = useState(false);

    useEffect(()=>{
        console.log('Modulo de mercado pago USE-EFFECT');
        handleCreatePreference()
    },[]);

    const handleCreatePreference = async () => {
        setIsLoading(true);
        let response = await create_preference_id(title,amount);
        console.log('RESPONSE DATA MERCADO PAGO ===> ',response);
        if(response.success=== true){
            setPreferenceID(response.data.preference_id);
            //AQUI DEBEMOS CREAR el initialization
        }
        setIsLoading(false);
    }

    // Asegúrate de tener el preference_id disponible antes de renderizar el Payment Brick
    if (isLoading) {
        return (
            <Box sx={{ mt: 10, textAlign: 'center' }}>
                <Container>
                    <Grid container spacing={3} justifyContent={'center'}>
                        <Grid item xl={6}>
                            <p>Cargando...</p>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        );
    }

    const initialization = {
        amount: amount,
        preferenceId: preferenceID,
    };
    const customization = {
        paymentMethods: {
            creditCard: "all", // Habilita todas las tarjetas de crédito
            debitCard: "all",  // Habilita todas las tarjetas de débito
            //ticket: "none",    // Deshabilita pagos con boletos (Efecty)
            //bankTransfer: "none", // Deshabilita transferencias bancarias (PSE)
            //mercadoPago: "none"   // Deshabilita dinero en cuenta de Mercado Pago
        },
        button: {
            text: buttonText, // Aquí cambias el texto del botón
            style: {
                backgroundColor: theme.palette.primary.main, // Cambias el color del fondo
                color: theme.palette.primary.contrastText,  // Cambias el color del texto
                padding: '15px 30px',
                borderRadius: '8px',  // Estilo del borde
                fontSize: '18px', // Tamaño de la fuente
            }
        }
    };



    const onSubmit = async ({ selectedPaymentMethod, formData }) => {
        
        // callback llamado al hacer clic en el botón enviar datos
        let response = await process_payment(formData,preferenceID);
        handleOnSubmit(response);
    };
    
    const onError = async (error) => {
        // callback llamado para todos los casos de error de Brick
        console.log(error);
    };

    const onReady = async () => {
        /*
        Callback llamado cuando el Brick está listo.
        Aquí puede ocultar cargamentos de su sitio, por ejemplo.
        */
        console.log('Ya podemos cobrar')
    };



    return(
        <Box sx={{mt:10}}>
            <Container>
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Payment
                            initialization={initialization}
                            customization={customization}
                            onSubmit={onSubmit}
                            onReady={onReady}
                            onError={onError}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
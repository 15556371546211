import { Box } from "@mui/material";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

export default function QUDAreaChart({data,dataKeyX,dataKey=[{name:'',strokeColor:'',fillColor:''}],dataKeyY,dataKeyY2,height=300,strokeColor="#147ac2",fillColor="#87aec9"})
{
    return(
        <Box sx={{width:'100%',height:height}}>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                    width={500}
                    height={400}
                    data={data}
                    margin={{
                        top: 10,
                        right: 30,
                        left: 0,
                        bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={dataKeyX} />
                    <YAxis />
                    <Tooltip />
                    {
                        dataKey.map(item=>
                            <Area type="monotone" dataKey={item.name} stroke={item.strokeColor} fill={item.fillColor} />
                        )
                    }
                </AreaChart>
            </ResponsiveContainer>
        </Box>
    )
}
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import DashboardIcon from '@mui/icons-material/Dashboard';
import BusinessIcon from '@mui/icons-material/Business';
import CategoryIcon from '@mui/icons-material/Category';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PasswordIcon from '@mui/icons-material/Password';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import LocalOffer from '@mui/icons-material/LocalOffer'
import FactCheckIcon from '@mui/icons-material/FactCheck';
import DevicesIcon from '@mui/icons-material/Devices';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import WalletIcon from '@mui/icons-material/Wallet';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ReviewsIcon from '@mui/icons-material/Reviews';

import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';

import { Box, Divider, ListItem, ListItemIcon, ListItemText, Tooltip, Typography } from '@mui/material';
import AuthContext from '../../navigation/context/authContext';
import UserContext from '../../navigation/context/userContext';
import { useTranslation } from 'react-i18next';





export default function MainListItems(){
    
    const {t, i18n} = useTranslation();
    const { signOut } = useContext(AuthContext);
    const { userData } = useContext(UserContext);
    let history = useHistory();
    
    const redireccionar = (ruta) => {
        history.push(ruta);
    }

    const handleSignOut = () => {
        history.replace('/');
        signOut();
    }

    return(
        <div>            
            <ListItem button onClick={() => redireccionar('/') }>
                <ListItemIcon>
                    <DashboardIcon sx={{color:'primary.contrastText'}} />
                </ListItemIcon>
                <ListItemText sx={{fontWeight:'bold'}} primary="Dashboard" />
            </ListItem>

            <ListItem button onClick = { () => redireccionar('/profile') } > 
                <ListItemIcon>
                    <BusinessIcon sx={{color:'primary.contrastText'}}/>
                </ListItemIcon>
                <ListItemText sx={{fontWeight:'bold'}} primary="Empresa" />
            </ListItem>

            <ListItem button onClick = { () => redireccionar('/reviews') } > 
                <ListItemIcon>
                    <ReviewsIcon sx={{color:'primary.contrastText'}}/>
                </ListItemIcon>
                <ListItemText sx={{fontWeight:'bold'}} primary={t('reviews.title')} />
            </ListItem>

            {
                userData.user.type === 'company' &&
                <>
                    <ListItem button onClick={()=>redireccionar('/coupons')}>
                        <ListItemIcon>
                            <CardGiftcardIcon sx={{color:'primary.contrastText'}}/>
                        </ListItemIcon>
                        <ListItemText sx={{fontWeight:'bold'}} primary="Cupones" />
                    </ListItem>

                    <ListItem button onClick={()=>redireccionar('/associates')}>
                        <ListItemIcon>
                            <ContactEmergencyIcon sx={{color:'primary.contrastText'}}/>
                        </ListItemIcon>
                        <ListItemText sx={{fontWeight:'bold'}} primary="Asociados" />
                    </ListItem>
                </>
            }

            
            
        
        {/** 
            <ListItem button>
                <ListItemIcon>
                <BarChartIcon />
                </ListItemIcon>
                <ListItemText primary="Reportes" />
            </ListItem>

            <ListItem button>
                <ListItemIcon>
                <LayersIcon />
                </ListItemIcon>
                <ListItemText primary="Integraciones" />
            </ListItem>

            

            <ListItem button>
                <ListItemIcon>
                <LayersIcon />
                </ListItemIcon>
                <ListItemText primary="Transacciones" />
            </ListItem>
            */}
        </div>
    )    
}
    
import React, { useContext, useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';

import menuIconQUD from '../../assets/images/logos/MenuQUD.png';
import logoQUD from '../../assets/images/logos/logoQUDW.png';
import { Avatar, Button, Fab, Menu, MenuItem, useMediaQuery } from '@mui/material';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import AuthContext from '../../navigation/context/authContext';
import { imagesURL } from '../../apiRoutes/apiBase';
import UserContext from '../../navigation/context/userContext';
import MainListItems from './mainList';
import { useHistory } from 'react-router-dom';
import Copyright from '../../components/copyRight';
import FinanceListItems from './financesList';
import SecurityListItems from './securityList';
import ManagementListItems from './management';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faCogs, faLanguage, faStore } from '@fortawesome/free-solid-svg-icons';

import en_language_img from '../../assets/images/countries/usa.png';
import es_language_img from '../../assets/images/countries/spain.png';

const drawerWidth = 240;
const tamLogo = 37;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function CompanyLayout(props) {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { userData, setUserData } = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { signOut } = useContext(AuthContext);
  const history = useHistory();

  // Detectar si es un dispositivo móvil
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const redirect = (_route) => {
    history.push(_route);
  };

  const handleLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  useEffect(() => {
    console.log(imagesURL + 'users/' + userData.entity.cover_image);
	console.log('USER DATA ===> ',userData)
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    history.replace('/');
    signOut();
  };

  return (
    <Box sx={{ display: 'flex', bgcolor: '#FFF' }}>
		<CssBaseline />
		<AppBar position="fixed" open={open}
			sx={{
				borderRadius: 0,
				padding: 0,
				zIndex: (theme) => theme.zIndex.drawer + 1,
				bgcolor: '#FFF'
			}}
		>
			<Toolbar>
				{!open && (
					<Button onClick={handleDrawerOpen}>
					<img src={menuIconQUD} style={{ height: tamLogo, marginLeft: -15 }} alt="QUDLogo" />
					</Button>
				)}
				<Box
					sx={{
					width: '100%',
					flexDirection: 'row',
					justifyContent: 'end',
					display: { xs: 'none', sm: 'flex' }
					}}
				>
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
						<Typography color={'primary.main'}>{userData.entity.last_name}</Typography>
						<Button onClick={handleMenu} aria-controls="menu-appbar">
							<Avatar sx={{ width: 50, height: 50 }} src={imagesURL + 'empresas/' + userData.entity.profile_image} />
						</Button>

						<Menu
							anchorEl={anchorEl}
							id="account-menu"
							open={Boolean(anchorEl)}
							onClose={handleClose}
							onClick={handleClose}
							PaperProps={{
								elevation: 0,
								sx: {
									overflow: 'visible',
									filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
									mt: 1.5,
									'& .MuiAvatar-root': {
									width: 32,
									height: 32,
									ml: -0.5,
									mr: 1,
									},
									'&:before': {
									content: '""',
									display: 'block',
									position: 'absolute',
									top: 0,
									right: 14,
									width: 10,
									height: 10,
									bgcolor: 'background.paper',
									transform: 'translateY(-50%) rotate(45deg)',
									zIndex: 0,
									},
								},
							}}
							transformOrigin={{ horizontal: 'right', vertical: 'top' }}
							anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
						>
							<MenuItem onClick={() => handleLanguage(i18n.language === 'es' ? 'en' : 'es')}>
								<img
									src={i18n.language === 'es' ? en_language_img : es_language_img}
									alt='language'
									style={{ width: 20 }}
								/>
								<Typography ml={2}>
									{i18n.language === 'es' ? 'EN' : 'ES'}
								</Typography>
							</MenuItem>
							<MenuItem onClick={() => redirect('/profile')}>
								<ListItemIcon>
									<FontAwesomeIcon icon={faStore} />
								</ListItemIcon>
								<Typography>{t('helpers.myBusinessProfile')}</Typography>
							</MenuItem>
							<MenuItem onClick={() => handleSignOut()}>
								<ListItemIcon>
									<ExitToAppIcon fontSize="small" />
								</ListItemIcon>
								<Typography>{t('actions.signOut')}</Typography>
							</MenuItem>
						</Menu>
					</div>
				</Box>
			</Toolbar>
		</AppBar>

		{/* Drawer para tablets y PCs */}
		{!isMobile && (
			<Drawer
				variant="permanent"
				open={open}
				sx={{ position: 'relative', whiteSpace: 'nowrap', '& .MuiPaper-root': { padding: 0, borderRadius: 0 } }}
				PaperProps={{
					sx: {
					backgroundColor: 'primary.main',
					color: 'primary.contrastText',
					borderRadius: 0,
					scrollbarWidth: 'none'
					}
				}}
			>
				<Toolbar>
					<img src={logoQUD} style={{ height: tamLogo }} alt="QUDLogo" />
					<IconButton style={{ marginLeft: 25, color: theme.palette.primary.contrastText }} onClick={handleDrawerClose}>
					{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
				</Toolbar>
				<Divider />
				<List>
					<MainListItems />
					<ManagementListItems open={open} />
					{userData.user.type === 'company' && <FinanceListItems open={open} />}
					<br />
					<Divider />
					<SecurityListItems />
				</List>
				<Divider />
			</Drawer>
		)}

		{/* Drawer temporal para móviles */}
		{isMobile && (
			<MuiDrawer
				variant="temporary"
				open={open}
				onClose={handleDrawerClose}
				ModalProps={{ keepMounted: true }}
				sx={{
					'& .MuiDrawer-paper': {
					width: drawerWidth,
					backgroundColor: 'primary.main',
					color: 'primary.contrastText',
					},
				}}
			>
				<Toolbar>
					<img src={logoQUD} style={{ height: tamLogo }} alt="QUDLogo" />
					<IconButton style={{ marginLeft: 25, color: theme.palette.primary.contrastText }} onClick={handleDrawerClose}>
					{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
					</IconButton>
				</Toolbar>
				<Divider />
				<List>
					<MainListItems />
					<ManagementListItems open={open} />
					{userData.user.idTipoUsuario === 2 && <FinanceListItems open={open} />}
					<br />
					<Divider />
					<SecurityListItems />
				</List>
				<Divider />
			</MuiDrawer>
		)}

		<Box component="main" sx={{ flexGrow: 1 }}>
			<Box sx={{ p: 3, bgcolor: '#FFF', minHeight: '95vh' }}>
				<DrawerHeader />
				{props.children}
			</Box>
			<Copyright />
		</Box>
    </Box>
  );
}
import WebsiteRoutes from "../../apiRoutes/WebsiteRoutes";
import { GET, POST, POST_JSON } from "../ConsumeApiController";

export async function createView(type,id,owner_id)
{
    var accessToken = localStorage.getItem('AccessToken');

    if(accessToken !== null)
        POST_JSON(WebsiteRoutes.Views.Create2,{type,id,owner_id});
    else    
        POST_JSON(WebsiteRoutes.Views.Create,{type,id,owner_id});
}
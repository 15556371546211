import { Button, Container, Divider, Grid, Paper, Typography, useTheme } from "@mui/material";
import CustomBreadcrumbs from "../../../components/breadcrumbs";
import { useTranslation } from "react-i18next";
import SectionTitle from "../../../components/sectionTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { faArrowsSpin, faInfoCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";

export default function Subscriptions()
{
    const {t, i18n} = useTranslation();

    const theme = useTheme();
    const history = useHistory();

    const redirect = (route) => {
        history.push(route)
    }

    return(
        <Container maxWidth='xl'>
            <CustomBreadcrumbs crumbs={[{name:`${t('subscriptions.title')}`,link:'/subscriptions'}]}/>
            <SectionTitle title={t('subscriptions.title')}/>
            <br/>
            
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Paper elevation={4}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <Typography variant="h4" component={'h1'} fontWeight={'bold'} gutterBottom>
                                    <FontAwesomeIcon icon={faArrowsSpin} color={theme.palette.link.main} />
                                    &nbsp;{t('subscriptions.panel')}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider/>
                        {/**ESTADISTICAS AQUI */}
                        <Grid container spacing={3} mt={2} mb={2}>
                            <Grid item lg={6} xl={6}>
                                <Grid container spacing={3} alignItems={'center'}>
                                    <Grid item xs={2} sm={2} md={2} lg={2} xl={2} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
                                        <FontAwesomeIcon color={theme.palette.link.main} size='2xl' icon={faInfoCircle}/>
                                    </Grid>
                                    <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                                        <Typography variant="h6" component={'h2'} fontWeight={'bold'}>
                                            Actualmente nos encontramos trabajando para mostrarte datos estadísticos sobre las reservaciones que has recibido
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" onClick={()=>redirect('/subscriptions/add')}>
                        <Typography variant="h6" mr={1}>{t('actions.add')}</Typography> <FontAwesomeIcon icon={faPlusCircle}/>
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}